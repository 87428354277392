<template>
  <ion-app>

    <ion-menu menu-id="nav" content-id="main-content" type="push">

      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Welcome Back!</ion-list-header>
          <ion-note>{{ username ? username : "" }}</ion-note>

          <ion-menu-toggle menu="nav" :auto-hide="false" v-for="(p, i) in appPages" :v-if="p.showIf" :key="i">
            <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none"
              :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
              <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
            <br>
          </ion-menu-toggle>
          <ion-list-header>Rooms</ion-list-header>
          <ion-menu-toggle :auto-hide="false" v-for="(p, i) in rooms" :v-if="p.showIf" :key="i">
            <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none"
              :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
              <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>


      </ion-content>
    </ion-menu>
    <ion-menu side="end" menu-id="users" content-id="main-content"  type="push">

      <ion-content>
        <ion-list id="user-list">
          <ion-list-header>User List - Chapter 1334</ion-list-header>
          <ion-note>{{ username ? username : "" }}</ion-note>
          <ion-list-header>Advisors</ion-list-header><ion-menu-toggle menu="users" :auto-hide="false"
            v-for="(p, i) in users.filter((e) => e.role.toLowerCase() == 'advisor')" :key="i">
            <User :p="p" :i="i"></User>
            <br>
          </ion-menu-toggle>
          <ion-list-header>Admins</ion-list-header><ion-menu-toggle :auto-hide="false"
            v-for="(p, i) in users.filter((e) => e.role.toLowerCase() == 'admin')" :key="i">
            <User :p="p" :i="i"></User>
            <br>
          </ion-menu-toggle>
          <ion-list-header>Members</ion-list-header><ion-menu-toggle :auto-hide="false"
            v-for="(p, i) in users.filter((e) => e.role.toLowerCase() == 'member')" :key="i">
            <User :p="p" :i="i"></User>
            <br>
          </ion-menu-toggle>
          <ion-list-header>Parents</ion-list-header>

          <ion-menu-toggle :auto-hide="false" v-for="(p, i) in users.filter((e) => e.role.toLowerCase() == 'parent')"
            :key="i">
            <User :p="p" :i="i"></User>
            <br>
          </ion-menu-toggle>
          <ion-list-header>Rooms</ion-list-header>

        </ion-list>


      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-app>
</template>

<script setup lang="ts">

import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonSpinner,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,

} from '@ionic/vue';
import { ref } from 'vue';
import * as outlines from 'ionicons/icons';
var users = [
  {
    name: "Joanthan",
    role: "Advisor",
    id: 1
  }, {
    name: "Joansthsan",
    role: "Admin",
    id: 2
  }, {
    name: "Joansthasn",
    role: "Parent",
    id : 3
  }, {
    name: "Joantsshan",
    role: "Member",
    id: 4
  },]
const selectedIndex = ref(0);
const appPages = [
  {
    title: 'Login',
    url: '/login',
    iosIcon: outlines.logInOutline,
    mdIcon: outlines.logInOutline,
    showIf: true
  }, {
    title: 'Signup',
    url: '/signup',
    iosIcon: outlines.createOutline,
    mdIcon: outlines.createOutline,
    showIf: false
  },
  {
    title: 'Chat',
    url: '/chat',
    iosIcon: outlines.sendOutline,
    mdIcon: outlines.sendOutline,
    showIf: false
  }, {
    title: 'Logout',
    url: '/logout',
    iosIcon: outlines.logOutOutline,
    mdIcon: outlines.logOutOutline,
    showIf: false
  },
];

const path = window.location.pathname.split('folder/')[1];
if (path !== undefined) {
  selectedIndex.value = appPages.findIndex((page) => page.title.toLowerCase() === path.toLowerCase());
}

</script>
<script lang="ts">
import { Device, DeviceInfo } from "@capacitor/device"
import User from './components/User.vue'
declare global {
  interface Window { device: DeviceInfo; }
}
export default {
  data() {
    return {
      username: undefined,
      type: "push",
      rooms: [
        {
          title: 'Chat',
          url: '/chat',
          iosIcon: outlines.sendOutline,
          mdIcon: outlines.sendOutline,
          showIf: false
        }
      ]
    }
  },

  components: {
    User
  },
  async beforeMount() {
    await Device.getInfo().then((info) => {
      window.device = info
    });

    while (!window.device.operatingSystem)
      if (['Win32', 'Win64', 'Windows', 'WinCE'].includes(window.device.operatingSystem)) this.type = "overlay"
      else this.type = "push"
  }
}
</script>
<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list, ion-menu.md ion-list#user-list {
  border-bottom: 1px solid var(--ion-background-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header,ion-menu.md ion-list#user-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
