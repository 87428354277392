<template>
    <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.name" lines="none"
        :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
        <ion-icon aria-hidden="true" slot="start" :icon="outlines.person" ></ion-icon>
        <ion-label>{{p.name}} - {{ p.id }}</ion-label>
      </ion-item>
</template>
<script setup>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonSpinner,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,

} from '@ionic/vue';
import { ref } from 'vue';
import * as outlines from 'ionicons/icons';</script>
<script>
export default {
    name: 'User',
    props: {
        p: Object,
        i: Number
    },
    data() {
        return {
            selectedIndex: 0
        }
    }
    

}
</script>